import {createBrowserRouter} from "react-router-dom";
import MainView from "../views/MainView";
import ESWSView from "../views/ESWSView";
import CBView from "../views/CBView";
import MVCFCView from "../views/MVCFCView";


const router = createBrowserRouter([
    {path: "/", element: <MainView/>},
    {path: "/esws", element: <ESWSView/>},
    {path: "/mvcfc", element: <MVCFCView/>},
    {path: "/cb", element: <CBView/>},
])

export {router}