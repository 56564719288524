import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"

import {router} from "../route";
import {useEffect} from "react";

import './main-view.scss'

const MainView = () => {

    // @ts-ignore
    const webApp = window.Telegram.WebApp;

    useEffect(() => {
        webApp.expand()
    }, [webApp]);


    const eswsClicked = () => {
        router.navigate("/esws")
    }

    const mvcfcClicked = () => {
        router.navigate("/mvcfc")
    }

    const cbClicked = () => {
        router.navigate("/cb")
    }

    return (
        <div className="main-view">
            <Container>
                <Stack spacing={2}>
                    <h1>Неонат бот</h1>
                    <Button className="main-view__nav-button" variant="contained" onClick={eswsClicked}>ESWS</Button>
                    <Button className="main-view__nav-button" variant="contained" onClick={mvcfcClicked}>mVcfc</Button>
                    <Button className="main-view__nav-button" variant="contained" onClick={cbClicked}>CB</Button>
                </Stack>
            </Container>
        </div>
    )
}

export default MainView