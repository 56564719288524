import React, {useEffect, useState} from "react";
import {Divider, Link, Paper, Stack, TextField} from "@mui/material";
import {router} from "../route";

const ESWSView = () => {
    const [adsr, setAdsr] = useState<string>('')
    const [lvids, setLvids] = useState<string>('')
    const [lvpws, setLvpws] = useState<string>('')
    const [esws, setEsws] = useState<string>('укажите ')

    function countESWS(adsr: string, lvids: string, lvpws: string): string {
        const adsrFloat = parseFloat(adsr)
        if (isNaN(adsrFloat)) {
            return 'неверный АДср'
        }
        const lvidsFloat = parseFloat(lvids)
        if (isNaN(lvidsFloat)) {
            return 'неверный LVIDs'
        }
        const lvpwsFloat = parseFloat(lvpws)
        if (isNaN(lvpwsFloat)) {
            return 'неверный LVPWs'
        }
        const esws = 1.35 * adsrFloat * (lvidsFloat / (4 * lvpwsFloat * (1 + lvpwsFloat / lvidsFloat)))
        return esws.toFixed(4)
    }

    useEffect(() => {
        const esws = countESWS(adsr, lvids, lvpws)
        setEsws(esws)
    }, [adsr, lvids, lvpws]);

    const backClicked = async () => {
        await router.navigate("/")
    }

    return (
        <div style={{padding: "1rem", backgroundColor: "#FFFFFF"}}>
            <Stack spacing={2}>
                <Link href="#" onClick={backClicked}>Назад</Link>
                <Divider/>
                <TextField label="АДср" variant="outlined" InputLabelProps={{shrink: true}} type="number"
                           inputProps={{pattern: "[0-9]*", inputmode: "decimal"}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setAdsr(event.target.value);
                           }}
                />
                <TextField label="LVIDs" variant="outlined" InputLabelProps={{shrink: true}}
                           inputProps={{pattern: "[0-9]*", inputmode: "decimal"}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setLvids(event.target.value);
                           }}/>
                <TextField label="LVPWs" variant="outlined" InputLabelProps={{shrink: true}}
                           inputProps={{pattern: "[0-9]*", inputmode: "decimal"}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setLvpws(event.target.value);
                           }}/>
                <Paper elevation={3} square style={{ padding: 24 }}><b>ESWS:</b> {esws}&nbsp;г/см2</Paper>
            </Stack>
        </div>
    );
}

export default ESWSView