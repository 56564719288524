import {Divider, Link, Paper, Stack, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {router} from "../route";

const MVCFCView = () => {

    const [lvids, setLvids] = useState<string>('')
    const [lvidd, setLvidd] = useState<string>('')
    const [rr, setRR] = useState<string>('')
    const [lvet, setLVET] = useState<string>('')
    const [mvcfc, setMVCFC] = useState<string>('')

    useEffect(() => {
        const mvcfc = calcMVCFC(lvidd, lvids, rr, lvet)
        setMVCFC(mvcfc)
    }, [lvids, lvidd, rr, lvet]);

    const calcMVCFC = (lvidd: string, lvids: string, rr: string, lvet: string): string => {
        const lviddFloat = parseFloat(lvidd)
        if (isNaN(lviddFloat)) {
            return 'Неверное значение LVIDd'
        }
        const lvidsFloat = parseFloat(lvids)
        if (isNaN(lvidsFloat)) {
            return 'Неверное значение LVIDs'
        }
        const rrFloat = parseFloat(rr)
        if (isNaN(rrFloat)) {
            return 'Неверное значение RR'
        }
        const lvetFloat = parseFloat(lvet)
        if (isNaN(lvetFloat)) {
            return 'Неверное значение LVET'
        }
        const mvcfc = (lviddFloat - lvidsFloat) * Math.sqrt(rrFloat) / lviddFloat / lvetFloat
        return mvcfc.toFixed(4)
    }


    const backClicked = async () => {
        await router.navigate("/")
    }

    return (
        <div style={{padding: "1rem", backgroundColor: "#FFFFFF"}}>
            <Stack spacing={2}>
                <Link href="#" onClick={backClicked}>Назад</Link>
                <Divider/>
                <TextField label="LVIDd" variant="outlined" InputLabelProps={{shrink: true}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setLvidd(event.target.value);
                           }}/>
                <TextField label="LVIDs" variant="outlined" InputLabelProps={{shrink: true}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setLvids(event.target.value);
                           }}/>
                <TextField label="RR" variant="outlined" InputLabelProps={{shrink: true}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setRR(event.target.value);
                           }}/>
                <TextField label="LVET" variant="outlined" InputLabelProps={{shrink: true}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setLVET(event.target.value);
                           }}/>
                <Paper elevation={3} square style={{ padding: 24 }}><b>MVCFC:</b>&nbsp;{mvcfc}</Paper>
            </Stack>
        </div>
    )
}

export default MVCFCView