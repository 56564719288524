import {Divider, Link, Paper, Stack, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {router} from "../route";

const CBView = () => {

    const [vti, setVTI] = useState<string>('')
    const [d, setD] = useState<string>('')
    const [chss, setCHSS] = useState<string>('')
    const [mass, setMass] = useState<string>('')
    const [cb, setCB] = useState<string>('')

    useEffect(() => {
        const cb = countCB(vti, d, chss, mass)
        setCB(cb)
    }, [vti, d, chss, mass]);

    const countCB = (vti: string, d: string, chss: string, mass: string): string => {
        const vtiFloat = parseFloat(vti)
        if (isNaN(vtiFloat)) {
            return 'Неверное значение VTI'
        }
        const dFloat = parseFloat(d)
        if (isNaN(dFloat)) {
            return 'Неверное значение D'
        }
        const chssFloat = parseFloat(chss)
        if (isNaN(chssFloat)) {
            return 'Неверное значение ЧСС'
        }
        const massFloat = parseFloat(mass)
        if (isNaN(massFloat)) {
            return 'Неверное значение массы'
        }
        const cb = vtiFloat * 3.14 * dFloat * dFloat / 4 * chssFloat / massFloat
        return cb.toFixed(4)
    }

    const backClicked = async () => {
        await router.navigate("/")
    }

    return (
        <div style={{padding: "1rem", backgroundColor: "#FFFFFF"}}>
            <Stack spacing={2}>
                <Link href="#" onClick={backClicked}>Назад</Link>
                <Divider/>
                <TextField label="VTI (см)" variant="outlined" InputLabelProps={{shrink: true}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setVTI(event.target.value);
                           }}/>
                <TextField label="d" variant="outlined" InputLabelProps={{shrink: true}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setD(event.target.value);
                           }}/>
                <TextField label="ЧСС" variant="outlined" InputLabelProps={{shrink: true}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setCHSS(event.target.value);
                           }}/>
                <TextField label="Масса (кг)" variant="outlined" InputLabelProps={{shrink: true}}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setMass(event.target.value);
                           }}/>
                <Paper elevation={3} square style={{ padding: 24 }}><b>Кровоток ВПВ:</b>&nbsp;{cb}</Paper>
            </Stack>
        </div>
    )
}

export default CBView